<template>
    <div class="Meeting">
        <div style="height: 65px;line-height: 65px" class="warp-padding flex">
            <div>
                <router-link to="/meeting"
                >
                    <a-icon
                            class="return"
                            type="left"
                    />&nbsp;&nbsp;创建学习计划
                </router-link
                >
            </div>
            <div>
                <router-link to="/meeting">
                    <a-button class="release">取消</a-button>
                </router-link>
                <a-button type="primary" @click="submitInfo" class="release"
                >发布
                </a-button
                >
            </div>
        </div>
        <a-row :gutter="19">
            <!--            左边-->
            <a-col :span="16">
                <div style="background: #ffffff" class="" class="warp-padding flex">
                    <div class="coverImg">
                        <div class="cover-upload">
                            <a-button style="position: relative">
                                {{ Photo ? '编辑封面图' : '设置封面图' }}
                                <input
                                        accept=".jpg,.png,.gif,.jpeg"
                                        type="file"
                                        class="upload-bth"
                                        id="Photo"
                                        @change="_upload($event, 'Photo')"
                                />
                            </a-button>
                        </div>
                        <img :src="Photo + '?imageView2/1/w/520/h/292'" alt=""/>
                    </div>

                    <div class="coverRight">
                        <a-input
                                placeholder="请输入标题"
                                v-model="Title"
                                class="line-margin"
                        />
                        <div class="flex line-margin">
                            <span class="person-text">负责人</span>
                            <a-avatar
                                    class="person-avatar"
                                    :size="42"
                                    icon="user"
                                    shape="circle"
                                    :src="PersonInChargeImg ? PersonInChargeImg : ''"
                            />
                            <person
                                    v-bind="personObj"
                                    style="flex:1"
                                    @getData="ChangeUser"
                            ></person>
                        </div>
                    </div>
                </div>
                <a-row :gutter="19" style="margin-bottom: 20px">
                    <a-col :span="10">
                        <div class="base-warp">
                            <h1 class="base-text">基本信息</h1>
                            <div class="flex group">
                                <span class="group-label">发布时间</span>
                                <!--                                    -->
                                <a-range-picker
                                        v-if="StartTime"
                                        :value="[
                    moment(StartTime, dateFormat),
                    moment(EndTime, dateFormat)
                  ]"
                                        show-time
                                        style="flex: 1"
                                        format="YYYY-MM-DD HH:mm:ss"
                                        :placeholder="['开始时间2', '结束时间2']"
                                        allowClear
                                        @change="ChangeTime"
                                />
                                <a-range-picker
                                        v-else
                                        show-time
                                        style="flex: 1"
                                        format="YYYY-MM-DD "
                                        :placeholder="['开始时间', '结束时间']"
                                        allowClear
                                        @change="ChangeTime"
                                />
                            </div>
                            <!--<div class="flex group">-->
                            <!--<span class="label-tips">提醒</span>-->
                            <!--<a-button style="flex: 1;color:#bfbfbf">+添加提醒</a-button>-->
                            <!--</div>-->
                            <div class="flex group">
                                <span class="group-label">地点</span>
                                <a-input
                                        v-model="MeetingAddress"
                                        style="flex: 1"
                                        placeholder="请输入地址"
                                />
                            </div>
                            <div class="flex group">
                                <span class="group-label">形式</span>
                                <a-select style="flex: 1" v-model="Form" placeholder="请选择">
                                    <a-select-option value="1">线上</a-select-option>
                                    <a-select-option value="2">线下</a-select-option>
                                    <a-select-option value="3">线上线下</a-select-option>
                                </a-select>
                            </div>
                            <div class="flex group">
                                <span class="group-label">参与方式</span>
                                <a-select
                                        style="flex: 1;margin-right: 5px"
                                        @change="ChangeJoinType"
                                        v-model="JoinType"
                                        placeholder="请选择"
                                >
                                    <a-select-option value="1">邀请</a-select-option>
                                    <a-select-option value="2">报名</a-select-option>
                                </a-select>
                            </div>
                            <div class="flex group">
                <span class="group-label">{{
                  JoinType == 1 ? '邀请人员' : '人数上限'
                }}</span>
                                <div
                                        v-if="JoinType == 1"
                                        style="flex:1;height:32px;overflow: hidden;border: 1px solid #d9d9d9;border-radius: 5px;line-height: 32px"
                                >
                                    <person
                                            style="border:0;opacity: 0"
                                            v-bind="personjoin"
                                            @getData="JoinUser"
                                    ></person>
                                </div>
                                <a-input
                                        v-model="SignUp"
                                        v-else
                                        placeholder="请输入人数上限"
                                        style="flex:1;"
                                />
                            </div>
                            <div class="flex group">
                                <span class="group-label">学费</span>
                                <a-input
                                        v-model="CostFree"
                                        style="flex: 1"
                                        placeholder="请输入学费"
                                />
                            </div>
                            <div class="flex group">
                                <span class="group-label">课程类型</span>
                                <a-select
                                        v-model="Category"
                                        style="flex: 1"
                                        placeholder="请选择课程类型"
                                >
                                    <a-select-option
                                            v-if="categoryList"
                                            v-for="item in categoryList"
                                            :value="item.Id"
                                    >{{ item.title }}
                                    </a-select-option
                                    >
                                </a-select>

                            </div>
                        </div>

                    </a-col>
                    <a-col :span="14">
                        <div class="base-warp" style="padding: 17px 20px">
                            <div class="flex">
                                <h1 class="base-text">
                                    参与人员
                                    <span style="color:rgba(153,153,153,1);font-size: 14px"
                                    >共{{ JoinPersoinCount }}人</span
                                    >
                                </h1>
                            </div>

                            <div
                                    class="group"
                                    style="display: inline-block;position: relative"
                                    v-if="JoinPersoin"
                                    v-for="(item, index) in JoinPersoin"
                            >
                                <a-avatar
                                        @mouseover="_hoverPerson(index)"
                                        class="person-avatar"
                                        :size="42"
                                        icon="user"
                                        shape="circle"
                                        :src="item.icon"
                                />
                                <div class="join-name">{{ item.title }}</div>
                                <a-popconfirm
                                        placement="bottom"
                                        cancelText="取消"
                                        okText="确定"
                                        @confirm="TutorDel(index, 2)"
                                        @cancel="maxClassify = -1"
                                        style="cursor: pointer"
                                >
                                    <template slot="title"><p>确定删除么？</p></template>
                                    <i
                                            v-if="PersonDelStatus == index"
                                            style="position: absolute;top: 90%;left: 50%;transform: translateX(-50%);"
                                            class="ant-menu-item-selected iconfont icon-shanchu-"
                                    ></i>
                                </a-popconfirm>
                            </div>
                        </div>
                    </a-col>
                </a-row>
                <!--                    导师介绍start-->
                <div style="" class="warp-padding">
                    <h1 class="base-text">课程概况</h1>
                    <div class="flex">
                        <h2 class="tutor-text">导师介绍</h2>
                        <a-dropdown>
                            <a-menu slot="overlay" @click="showDrawer">
                                <a-menu-item key="2">外部</a-menu-item>
                                <a-menu-item key="1">内部</a-menu-item>
                            </a-menu>
                            <a-button type="primary">
                                添加导师
                                <a-icon type="down"/>
                            </a-button>
                        </a-dropdown>
                    </div>

                    <div
                            class="tutor"
                            v-if="tutorList"
                            v-for="(item, index) in tutorList"
                    >
                        <div class="flex">
                            <div style="width: 92px">
                                <a-avatar
                                        class="person-avatar"
                                        :size="92"
                                        icon="user"
                                        shape="circle"
                                        :src="item.icon + '?imageView2/1/w/92/h/92'"
                                />
                            </div>

                            <div class="tutor-info">
                                <div class="tutor-name">
                                    <span style="margin-right:5px">{{ item.title }}</span>
                                    <i
                                            @click="SaveTutor(index)"
                                            style="margin-right:5px;cursor: pointer"
                                            @mouseover="_hoverActive(index, 1)"
                                            :class="active2 == index ? 'ant-menu-item-selected' : ''"
                                            class="iconfont icon-bianji"
                                            @mouseout="_hoverHide"
                                    ></i>
                                    <a-popconfirm
                                            placement="bottom"
                                            cancelText="取消"
                                            okText="确定"
                                            @confirm="TutorDel(index, 1)"
                                            @cancel="maxClassify = -1"
                                            style="cursor: pointer"
                                    >
                                        <template slot="title">
                                            <p>确定删除么？</p>
                                        </template>
                                        <i
                                                @mouseover="_hoverActive(index, 2)"
                                                class="iconfont icon-shanchu-"
                                                :class="active == index ? 'ant-menu-item-selected' : ''"
                                                @mouseout="_hoverHide"
                                        ></i>
                                    </a-popconfirm>
                                </div>
                                <div class="tutor-base">{{ item.Info }}</div>
                            </div>
                        </div>
                    </div>

                    <h2 class="tutor-text">课程介绍</h2>
                    <a-textarea
                            v-model="Remark"
                            placeholder="请输入课程介绍"
                            :autosize="{ minRows: 6 }"
                    />
                </div>
                <!--                    导师介绍end-->
            </a-col>

            <!--                右边-->
            <a-col :span="8">
                <div class="warp-padding" style="height: 346px;overflow-y: auto">
                    <div class="flex drawer-content-bottom">
                        <h1 class="base-text">相关视频</h1>
                        <a-button
                                type="primary"
                                style="position: relative"
                                @click="visibleVideo = true"
                        >
                            添加视频
                            <!-- <input  type="file" class='upload-bth' id="Video" @change="_upload($event,'Video')"> -->
                        </a-button>
                    </div>
                    <div v-show="Video" v-for="(item, index) in Video" class="video">
                        <!--:poster="item.FileUrl+'?vframe/jpg/offset/1'"-->
                        <video controls="controls" :src="item.FileUrl"></video>
                        <div class="video-title">{{ item.FileName }}</div>
                        <div class="flex">
                            <span class="video-upload-time">上传于{{ item.nowTime }}</span>
                            <i
                                    @mouseover="_hoverActive(index, 1)"
                                    :class="active2 == index ? 'ant-menu-item-selected' : ''"
                                    class="iconfont icon-bianji"
                                    @mouseout="_hoverHide"
                                    @click="SaveVideo(index)"
                            ></i>
                            <a-popconfirm
                                    placement="bottom"
                                    cancelText="取消"
                                    okText="确定"
                                    @confirm="VideoDel(index)"
                                    @cancel="maxClassify = -1"
                            >
                                <template slot="title">
                                    <p>确定删除么？</p>
                                </template>
                                <i
                                        @mouseover="_hoverActive(index, 2)"
                                        class="iconfont icon-shanchu-"
                                        :class="active == index ? 'ant-menu-item-selected' : ''"
                                        @mouseout="_hoverHide"
                                ></i>
                            </a-popconfirm>
                        </div>
                    </div>
                </div>
                <div class="warp-padding" style="height: 330px;overflow-y: auto">
                    <div class="flex drawer-content-bottom">
                        <h1 class="base-text">活动风采</h1>
                    </div>
                    <div v-viewer>
                        <div
                                v-if="files"
                                v-for="(item, index) in files"
                                class="imsges"
                                style="position: relative"
                        >
                            <img :src="item.FileUrl" alt=""/>
                            <div class="img-del">
                                <a-popconfirm
                                        placement="bottom"
                                        cancelText="取消"
                                        okText="确定"
                                        @confirm="ImgDel(index, item.Id)"
                                        @cancel="maxClassify = -1"
                                >
                                    <template slot="title">
                                        <p>确定删除么？</p>
                                    </template>
                                    <i
                                            style="color:#ffffff;cursor: pointer"
                                            class="iconfont icon-shanchu- ant-menu-item-selected"
                                    ></i>
                                </a-popconfirm>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="warp-padding">
                    <div class="flex drawer-content-bottom">
                        <h1 class="base-text">所用课件</h1>
                        <!--<a-upload>-->
                        <!--<a-button  v-if="Id" type="primary" >上传课件 </a-button>-->
                        <!--</a-upload>-->
                    </div>
                    <div
                            class="flex"
                            style="justify-content: left;margin-bottom: 15px;flex-wrap: wrap"
                    >
                        <div v-if="tutorList" v-for="(item, key) in tutorList">
                            <div
                                    class="upload-file-item"
                                    v-for="(items, index) in item.CourseFile"
                            >
                                <a-icon type="file-pdf"/>
                                <div
                                        style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                                >
                                    {{ items.FileName }}
                                </div>
                                <a-popconfirm
                                        placement="bottom"
                                        cancelText="取消"
                                        okText="确定"
                                        @confirm="CourseDel(key, index)"
                                        @cancel="maxClassify = -1"
                                >
                                    <template slot="title">
                                        <p>确定删除么？</p>
                                    </template>
                                    <i class="iconfont icon-shanchu- ant-menu-item-selected"></i>
                                </a-popconfirm>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="warp-padding">
                    <div class="flex drawer-content-bottom">
                        <h1 class="base-text">试卷</h1>
                        <a-button @click="QuireData(2)" type="primary">选择试卷</a-button>
                    </div>
                    <div
                            v-if="PaperInfo"
                            v-for="(item, index) in PaperInfo"
                            class="quire-content"
                    >
                        <span class="quire-icon"></span>
                        <span class="quire-title">{{ item.Title }}</span>
                        <a-popconfirm
                                placement="bottom"
                                cancelText="取消"
                                okText="确定"
                                @confirm="QuireDel(item.Id, 'PaperId', index)"
                                @cancel="maxClassify = -1"
                        >
                            <template slot="title">
                                <p>确定删除么？</p>
                            </template>
                            <i class="iconfont icon-shibai"></i>
                        </a-popconfirm>
                    </div>
                </div>
                <div class="warp-padding">
                    <div class="flex drawer-content-bottom">
                        <h1 class="base-text">问卷</h1>
                        <a-button @click="QuireData(1)" type="primary">选择问卷</a-button>
                    </div>
                    <div
                            v-if="QuesData"
                            v-for="(item, index) in QuesData"
                            class="quire-content"
                    >
                        <span class="Paper-bg"></span>
                        <span class="quire-title">{{ item.Title }}</span>
                        <a-popconfirm
                                placement="bottom"
                                cancelText="取消"
                                okText="确定"
                                @confirm="QuireDel(item.Id, 'QuestionnaireId', index)"
                                @cancel="maxClassify = -1"
                        >
                            <template slot="title">
                                <p>确定删除么？</p>
                            </template>
                            <i class="iconfont icon-shibai"></i>
                        </a-popconfirm>
                    </div>
                </div>
            </a-col>
        </a-row>
        <!--添加视频  -->
        <a-drawer
                width="400px"
                placement="right"
                :closable="false"
                @close="onClose('visibleVideo')"
                :visible="visibleVideo"
        >
            <div class="drawer-head">添加视频</div>
            <div class="drawer">
                <div class=" drawer-content-bottom">
                    <a-textarea
                            placeholder="请粘贴,多个连接请以英文逗号(',')隔开"
                            v-model="VideoAdd"
                            :rows="3"
                    ></a-textarea>
                    请将视频在文件柜，在视频详情信息里复制链接过来，
                </div>
            </div>
            <div class="drawer-bottom">
                <div style="width: 150px" class="flex">
                    <a-button style="margin-right: 20px" @click="onClose('visibleVideo')"
                    >取消
                    </a-button
                    >
                    <a-button @click="videoAddFn()" type="primary">确认</a-button>
                </div>
            </div>
        </a-drawer>
        <!--导师抽屉start-->
        <a-drawer
                width="400px"
                placement="right"
                :closable="false"
                @close="onClose('visible')"
                :visible="visible"
        >
            <div class="drawer-head">导师信息</div>
            <div class="drawer">
                <div class="flex drawer-content-bottom">
                    <div style="position: relative">
                        <a-avatar
                                class="person-avatar"
                                :size="52"
                                icon="user"
                                shape="circle"
                                :src="tutorImg + '?imageView2/1/w/92/h/92'"
                        />
                        <input
                                accept=".jpg,.png,.gif,.jpeg"
                                v-if="tutorType == 2"
                                type="file"
                                class="upload-bth"
                                id="tutorImg"
                                @change="_upload($event, 'tutorImg')"
                        />
                    </div>

                    <person
                            v-bind="persontutor"
                            v-if="tutorType == 1"
                            style="flex:1;margin-left: 15px;"
                            @getData="addtutor"
                    ></person>
                    <a-input
                            v-else
                            placeholder="请输入主讲人姓名"
                            v-model="SpeakerName"
                            style="flex: 1;margin-left: 15px"
                    />
                </div>
                <div class="drawer-content-bottom">
                    <a-textarea
                            v-model="Info"
                            placeholder="主讲人简介"
                            :autosize="{ minRows: 6 }"
                    />
                </div>
                <div class="flex drawer-content-bottom">
                    <span class="label-tips">主讲时间</span>
                    <a-date-picker
                            v-if="SpeakerTime"
                            :value="moment(SpeakerTime, 'YYYY-MM-DD HH:mm:ss')"
                            style="flex:1;margin-left: 15px;"
                            show-time
                            format="YYYY-MM-DD HH:mm:ss"
                            @change="ChangeSpeakerTime"
                    />
                    <a-date-picker
                            v-else
                            style="flex:1;margin-left: 15px;"
                            show-time
                            format="YYYY-MM-DD HH:mm:ss"
                            @change="ChangeSpeakerTime"
                    />
                </div>
                <div class="flex drawer-content-bottom">
                    <span class="label-tips">所用课件</span>
                    <a-button type="primary" style="position: relative">
                        上传课件
                        <input
                                type="file"
                                class="upload-bth"
                                id="CourseFile"
                                @change="_upload($event, 'CourseFile')"
                        />
                    </a-button>
                </div>
                <div
                        class="flex"
                        style="justify-content: left;margin-bottom: 15px;flex-wrap: wrap"
                        v-if="tutorCourseFile"
                >
                    <div
                            class="upload-file-item"
                            v-for="(item, index) in tutorCourseFile"
                    >
                        <a-icon type="file-pdf"/>
                        <div
                                style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden"
                        >
                            {{ item.FileName }}
                        </div>
                        <a-popconfirm
                                placement="bottom"
                                cancelText="取消"
                                okText="确定"
                                @confirm="CourseDels(index)"
                                @cancel="maxClassify = -1"
                        >
                            <template slot="title">
                                <p>确定删除么？</p>
                            </template>
                            <i class="iconfont icon-shanchu- ant-menu-item-selected"></i>
                        </a-popconfirm>
                    </div>
                </div>
                <div class="flex drawer-content-bottom">
                    <span class="label-tips">提醒导师</span>
                    <a-button type="primary" style="position: relative"
                    >提醒导师
                    </a-button
                    >
                </div>
            </div>
            <div class="drawer-bottom">
                <div style="width: 150px" class="flex">
                    <a-button style="margin-right: 20px" @click="onClose('visible')"
                    >取消
                    </a-button
                    >
                    <a-button @click="TutorInfo" type="primary">确认</a-button>
                </div>
            </div>
        </a-drawer>

        <!--导师抽屉end-->
        <!--试卷抽屉start-->
        <a-drawer
                width="800px"
                placement="right"
                :closable="false"
                @close="onClose('quirevisible')"
                :visible="quirevisible"
        >
            <div class="drawer-head">
                {{ QuireType == 1 ? '问卷信息' : '试卷信息' }}
            </div>
            <div class="search-flex">
                <div class="search-list-item">
                    <span class="search-item-label ">{{ quireTitle }}</span>
                    <a-input
                            v-model="Keyword"
                            placeholder="请输入关键字"
                            class="search-item-input"
                    />
                </div>
            </div>
            <div class="search-flex" style="width:200px">
                <div class="search-list-item">
                    <a-button
                            size="large"
                            class="search-item-button"
                            @click="QuireData(QuireType)"
                            type="primary"
                    >查询
                    </a-button
                    >
                    <a-button class="search-item-button">重置</a-button>
                </div>
            </div>
            <a-table
                    v-if="quirevisible"
                    bordered
                    :rowSelection="rowSelection"
                    :columns="columns"
                    :dataSource="quireList"
            >
                <!--<a slot="sNumber" slot-scope="sNumber" href="javascript:;">{{sNumber}}</a>-->
            </a-table>

            <div class="drawer-bottom">
                <div style="width: 150px" class="flex">
                    <a-button style="margin-right: 20px" @click="onClose('quirevisible')"
                    >取消
                    </a-button
                    >
                    <a-button @click="quireAdd(QuireType)" type="primary">确认</a-button>
                </div>
            </div>
        </a-drawer>
        <!--试卷抽屉end-->
        <!--视频抽屉start-->
        <a-drawer
                width="500px"
                placement="right"
                :closable="false"
                @close="onClose('videovisible')"
                :visible="videovisible"
        >
            <div class="drawer-head">视频信息</div>
            <div class="flex drawer-content-bottom">
                <span class="label-tips">视频名称</span>
                <video
                        style="width:300px;height:300px"
                        controls="controls"
                        :src="VideoData.FileUrl"
                ></video>
            </div>
            <div class="flex drawer-content-bottom">
                <span class="label-tips">视频名称</span>
                <a-input v-model="VideoName" style="flex: 1;margin-left: 15px"/>
            </div>

            <div class="drawer-bottom">
                <div style="width: 150px" class="flex">
                    <a-button style="margin-right: 20px" @click="onClose('videovisible')"
                    >取消
                    </a-button
                    >
                    <a-button @click="VideoEdit(VideoNum)" type="primary">确认</a-button>
                </div>
            </div>
        </a-drawer>
        <!--视频抽屉end-->
    </div>
</template>
<script>
    import 'viewerjs/dist/viewer.css'
    import Viewer from 'v-viewer'
    import Vue from 'vue'

    Vue.use(Viewer)

    import person from '../../components/SelectPersonnel/SelectPersonnel'
    import moment from 'moment'

    export default {
        name: 'meeting',
        components: {person},
        data() {
            return {
                dateFormat: 'YYYY-MM-DD HH:mm:ss',
                Photo: '',
                Video: [],
                PersonInCharge: '',
                Title: '',
                JoinType: '1',
                JoinPersoin: '',
                JoinPersoinCount: '0',
                visible: false,
                quirevisible: false,
                videovisible: false,
                visibleVideo: false,
                tutorList: [],
                tutorType: '1',
                Remark: '',
                MeetingAddress: '',
                SignUp: '',
                tutorImg: '',
                StartTime: '',
                EndTime: '',
                Form: '1',
                files: '',
                tutorCourseFile: '',
                Info: '',
                bool: false,
                CourseFiles: '',
                personObj: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: '', //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                },
                personjoin: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 1000, //选的数量
                    department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: '', //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                },
                persontutor: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: '', //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                },
                PersonInChargeImg: '',
                userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
                Keyword: '',
                SpeakerTime: '',
                columns: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        scopedSlots: {customRender: 'sNumber'}
                    },
                    {title: '试卷名称', dataIndex: 'Title'},
                    {title: '所属分类', dataIndex: 'TypeName'}
                ],
                Changequirelist: '',
                ChangePaperlist: '',
                QuireType: '',
                PaperInfo: '',
                QuesData: '',
                tutorPerson: '',
                active: -1,
                active2: -1,
                nowTime: '',
                VideoFile: [],
                SpeakerName: '',
                PersonDelStatus: -1,
                tutorbase: [],
                VideoData: '',
                VideoNum: '',
                VideoName: '',
                SaveTutorStatus: '',
                quireTitle: '试卷名称',
                total: '',
                page: '',
                quireList: [],
                VideoAdd: '',
                CostFree:0,
                categoryList:[],
                Category:'',

            }
        },
        created() {
            // this.$axios.get(1088,{
            //
            // },res=>{
            //     console.log(res);
            // })
            this._categoryList();
        },
        computed: {
            rowSelection() {
                const {selectedRowKeys} = this
                return {
                    onChange: (selectedRowKeys, selectedRows) => {
                        let selectedId = []
                        if (selectedRows.length > 0) {
                            for (var i = 0; i < selectedRows.length; i++) {
                                selectedId[i] = selectedRows[i]['Id']
                            }
                        }
                        if (this.QuireType == 1) {
                            if (this.QuesData) {
                                this.QuesData.push(selectedRows[0])
                            } else {
                                this.QuesData = selectedRows
                            }
                            this.Changequirelist = selectedId
                        } else {
                            if (this.PaperInfo) {
                                this.PaperInfo.push(selectedRows[0])
                            } else {
                                this.PaperInfo = selectedRows
                            }
                            this.ChangePaperlist = selectedId
                        }
                    },
                    getCheckboxProps: record => ({
                        props: {
                            disabled: record.name === 'Disabled User', // Column configuration not to be checked
                            name: record.name
                        }
                    })
                }
            }
        },
        methods: {
            moment,
            _categoryList() {
                let self = this
                this.$axios.get(
                    1200,
                    {
                        label: 'Study'
                    },
                    res => {
                        self.categoryList = res.data.data
                    }
                )
            },
            _upload(e, name) {
                let self = this
                let files = document.getElementById(name).files
                this.__uploadFile__(files).then(res => {
                    // console.log(res[0]);
                    if (res.length > 0) {
                        let url = res[0]['FileUrl']
                        if (name == 'Photo') {
                            self.Photo = url
                        }
                        if (name == 'Video') {
                            self.getDate()
                            self.VideoFile = res[0]
                            self.VideoFile.nowTime = self.nowTime
                            self.Video.push(self.VideoFile)
                        }
                        if (name == 'tutorImg') {
                            self.tutorImg = url
                        }
                        if (name == 'CourseFile') {
                            if (self.tutorCourseFile.length > 0) {
                                //self.CourseFiles.push(res[0]);
                                self.tutorCourseFile.push(res[0])
                            } else {
                                //self.CourseFiles = res;
                                self.tutorCourseFile = res
                            }
                        }
                        self.$message.success('上传成功')
                    } else {
                        this.$message.error('上传文件失败!')
                    }
                })
            },
            videoAddFn() {
                console.log(this.Video, this.VideoAdd)
                this.visibleVideo = false
                let that = this
                if (that.VideoAdd) {
                    that.$axios.get(8019, {Urls: that.VideoAdd}, res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            that.Video = res.data.data
                        } else {
                            that.$message.error(res.data.message)
                        }
                    })
                }
            },
            TutorInfo() {
                if (this.Info == '') {
                    this.$message.error('请输入导师简介')
                    return false
                }
                if (this.SpeakerTime == '') {
                    this.$message.error('请选择导师的主讲时间')
                    return false
                }

                if (this.SaveTutorStatus == '0' || this.SaveTutorStatus) {
                    if (this.tutorType == 2) {
                        if (this.tutorImg == '') {
                            this.$message.error('请先上传导师头像')
                            return false
                        }
                        if (this.SpeakerName == '') {
                            this.$message.error('请输入导师名字')
                            return false
                        }
                        let tutorinfos = {}
                        if (this.tutorCourseFile) {
                            tutorinfos.CourseFile = this.tutorCourseFile
                        }
                        tutorinfos.icon = this.tutorImg
                        tutorinfos.title = this.SpeakerName
                        tutorinfos.Info = this.Info
                        tutorinfos.SpeakerTime = this.SpeakerTime
                        if (this.SaveTutorStatus == '0' || this.SaveTutorStatus) {
                            this.tutorList.splice(this.SaveTutorStatus, 1)
                        }
                        this.tutorList.push(tutorinfos)
                    } else {
                        this.tutorPerson.Info = this.Info
                        this.tutorPerson.SpeakerTime = this.SpeakerTime
                        this.tutorPerson.CourseFile = this.tutorCourseFile
                        if (this.SaveTutorStatus == '0' || this.SaveTutorStatus) {
                            this.tutorList.splice(this.SaveTutorStatus, 1)
                        }
                        this.tutorList.push(this.tutorPerson)
                    }
                } else {
                    if (this.tutorType == 2) {
                        if (this.tutorImg == '') {
                            this.$message.error('请先上传导师头像')
                            return false
                        }
                        if (this.SpeakerName == '') {
                            this.$message.error('请输入导师名字')
                            return false
                        }
                        let tutorinfos = {}
                        if (this.tutorCourseFile) {
                            tutorinfos.CourseFile = this.tutorCourseFile
                        }
                        tutorinfos.icon = this.tutorImg
                        tutorinfos.title = this.SpeakerName
                        tutorinfos.Info = this.Info
                        tutorinfos.SpeakerTime = this.SpeakerTime
                        if (this.SaveTutorStatus == '0' || this.SaveTutorStatus) {
                            this.tutorList.splice(this.SaveTutorStatus, 1)
                        }
                        this.tutorList.push(tutorinfos)
                    } else {
                        this.tutorPerson.Info = this.Info
                        this.tutorPerson.SpeakerTime = this.SpeakerTime
                        this.tutorPerson.CourseFile = this.tutorCourseFile
                        this.tutorList.push(this.tutorPerson)
                    }
                }
                this.persontutor.selectArr = ''
                this.SpeakerName = ''
                this.tutorPerson = ''
                this.SaveTutorStatus = ''
                this.Info = ''
                this.tutorImg = ''
                this.SpeakerTime = ''
                this.tutorCourseFile = ''
                this.visible = false
            },
            SaveTutor(i) {
                this.Info = this.tutorList[i].Info
                this.tutorImg = this.tutorList[i].icon
                this.tutorCourseFile = this.tutorList[i].CourseFile
                this.SpeakerTime = this.tutorList[i].SpeakerTime
                if (this.tutorList[i].id) {
                    //内部导师
                    this.tutorType = 1
                    let tutorbases = {}
                    tutorbases.icon = this.tutorList[i].icon
                    tutorbases.id = this.tutorList[i].id
                    tutorbases.isuser = this.tutorList[i].isuser
                    tutorbases.position = this.tutorList[i].position
                    tutorbases.title = this.tutorList[i].title
                    this.tutorbase.push(tutorbases)
                    this.persontutor.selectArr = this.tutorbase
                    this.tutorPerson = this.tutorbase[0]
                    this.tutorbase = []
                } else {
                    this.tutorType = 2
                    this.SpeakerName = this.tutorList[i].title
                }
                this.SaveTutorStatus = i
                this.tutorImg = this.tutorList[i].icon
                this.visible = true
            },
            TutorDel(i, type) {
                if (type == 2) {
                    this.JoinPersoin.splice(i, 1)
                    this.JoinPersoinCount = this.JoinPersoin.length
                } else {
                    this.tutorList.splice(i, 1)
                }
            },
            SaveVideo(i) {
                this.VideoNum = i
                this.VideoData = this.Video[i]
                this.VideoName = this.Video[i]['FileName']
                this.videovisible = true
            },
            VideoEdit(i) {
                let self = this
                self.Video.splice(i, 1)
                self.VideoData.FileName = self.VideoName
                self.Video.push(self.VideoData)
                self.videovisible = false
            },
            VideoDel(i) {
                let self = this
                if (self.Video.length == 1) {
                    self.Video = []
                } else {
                    self.Video = self.Video.splice(i, 1)
                }
            },
            ImgDel(i) {
                if (this.files.length == 0) {
                } else {
                    this.files.splice(i, 1)
                }
            },
            //主讲时间
            ChangeSpeakerTime(date, dateString) {
                this.SpeakerTime = dateString
            },
            getCurrentData() {
                if (this.SpeakerTime) {
                    return this.SpeakerTime
                } else {
                    return new Date().toLocaleDateString()
                }
            },
            getDate() {
                let date = new Date()
                let seperator1 = '-'
                let year = date.getFullYear()
                let month = date.getMonth() + 1
                let strDate = date.getDate()
                if (month >= 1 && month <= 9) {
                    month = '0' + month
                }
                if (strDate >= 0 && strDate <= 9) {
                    strDate = '0' + strDate
                }
                let currentdate = year + seperator1 + month + seperator1 + strDate
                this.nowTime = currentdate
            },

            submitInfo() {
                let Title = this.Title
                let Remark = this.Remark
                let SignUp = this.SignUp
                let PersonInCharge = JSON.stringify(this.PersonInCharge)
                let Photo = this.Photo
                let Video = JSON.stringify(this.Video)
                let tutorList = JSON.stringify(this.tutorList)
                let JoinPersoin = JSON.stringify(this.JoinPersoin)
                let MeetingAddress = this.MeetingAddress
                let StartTime = this.StartTime
                let EndTime = this.EndTime
                let JoinType = this.JoinType
                let Form = this.Form
                let self = this
                let PaperId = this.ChangePaperlist
                let QuestionnaireId = this.Changequirelist
                if (Title == '') {
                    this.$message.error('请输入标题')
                    return false
                }
                if (!Photo) {
                    this.$message.error('请上传封面图')
                    return false
                }
                if (StartTime == '' || EndTime == '') {
                    this.$message.error('请选择开始时间和结束时间')
                    return false
                }
                if (MeetingAddress == '') {
                    this.$message.error('请输入地址')
                    return false
                }
                if (Form == '') {
                    this.$message.error('请选择参与形式')
                    return false
                }
                if(!this.Category){
                    this.$message.error('请选择课程类型')
                    return false
                }
                if (JoinType == '') {
                    this.$message.error('请选择参与方式')
                    return false
                }
                this.$axios.post(
                    1022,
                    {
                        Title: Title,
                        Remark: Remark,
                        SignUp: SignUp,
                        PersonInCharge: PersonInCharge,
                        Photo: Photo,
                        Video: Video,
                        join: JoinPersoin,
                        tutorList: tutorList,
                        MeetingAddress: MeetingAddress,
                        CreateUserId: self.userinfo.uid,
                        CreateUser: self.userinfo.name,
                        Form: Form,
                        JoinType: JoinType,
                        StartTime: StartTime,
                        EndTime: EndTime,
                        Type: 1,
                        PaperId: PaperId,
                        QuestionnaireId: QuestionnaireId,
                        CostFree:self.CostFree,
                        Category:self.Category,
                    },
                    res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            this.$router.push({path: '/meeting'})
                            this.$message.success('操作成功')
                            this.loadData()
                        } else {
                            this.$message.error('操作失败')
                        }
                    }
                )
            },
            CourseDel(i, num) {
                this.tutorList[i]['CourseFile'].splice(num)
            },
            CourseDels(i) {
                this.tutorCourseFile.splice(i)
            },
            ChangeTime(time, timeString) {
                this.StartTime = timeString[0]
                this.EndTime = timeString[1]
            },

            QuireData(value) {
                let self = this
                this.QuireType = value
                let QuestionnaireId = ''
                if (value == 1) {
                    //问卷
                    this.columns[1].title = '问卷名称'
                    this.quireTitle = '问卷名称'
                    QuestionnaireId = JSON.stringify(this.QuesData)
                } else {
                    this.columns[1].title = '试卷名称'
                    this.quireTitle = '试卷名称'
                    QuestionnaireId = JSON.stringify(this.PaperInfo)
                }
                this.$axios.get(
                    1021,
                    {
                        Type: value,
                        Keyword: self.Keyword,
                        QuestionnaireId: QuestionnaireId
                    },
                    res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            self.quirevisible = true
                            self.quireList = []
                            self.quireList = res.data.data
                            console.log(self.quireList)
                        } else {
                            self.quireList = ''
                            this.$message.error('查询数据失败!')
                        }
                    }
                )
            },
            quireAdd() {
                this.QuestionnaireId = this.Changequirelist
                this.PaperId = this.ChangePaperlist
                this.quirevisible = false
            },
            QuireDel(QuireId, Field, i) {
                if (Field == 'QuestionnaireId') {
                    this.QuesData.splice(i)
                    this.QuestionnaireId.splice(i)
                } else {
                    this.PaperInfo.splice(i)
                    this.PaperId.splice(i)
                }
            },
            //导师抽屉
            showDrawer(e) {
                this.tutorType = e.key
                this.visible = true
            },
            onClose(value) {
                this.Keyword = ''
                this.quireList = ''
                if (value == 'quirevisible') {
                    this.quirevisible = false
                    this.ChangePaperlist = ''
                    this.Changequirelist = ''
                    this.QuireType = ''
                }
                if (value == 'videovisible') {
                    this.videovisible = false
                }
                if (value == 'visibleVideo') {
                    this.visibleVideo = false
                    this.VideoAdd = ''
                }
                if (value == 'visible') {
                    this.persontutor.selectArr = ''
                    this.SpeakerName = ''
                    this.tutorPerson = ''
                    this.SaveTutorStatus = ''
                    this.Info = ''
                    this.tutorImg = ''
                    this.SpeakerTime = ''
                    this.tutorCourseFile = ''
                    this.visible = false
                }
            },
            //参与方式
            ChangeJoinType(value) {
                this.JoinType = value
            },
            //导师添加
            addtutor(value) {
                if (value.length > 0) {
                    this.tutorPerson = value[0]
                    if (value[0].icon) {
                        this.tutorImg = value[0].icon
                    }
                } else {
                    this.tutorPerson = ''
                    this.tutorImg = ''
                }
            },
            //负责人
            ChangeUser(value) {
                this.PersonInCharge = value
                console.log(value)
                if (value.length > 0) {
                    this.PersonInChargeImg = value[0].icon
                } else {
                    this.PersonInChargeImg = ''
                }
            },
            //参与人
            JoinUser(value) {
                if (value.length > 0) {
                    this.JoinPersoin = value
                } else {
                    this.JoinPersoin = ''
                }
                this.JoinPersoin = value
                this.JoinPersoinCount = this.JoinPersoin.length
            },
            _hoverPerson(i) {
                this.PersonDelStatus = i
            },
            _hoverActive(i, type) {
                if (type == 1) {
                    this.active2 = i
                    this.active = -1
                } else {
                    this.active = i
                    this.active2 = -1
                }
            },
            _hoverHide() {
                this.active2 = -1
                this.active = -1
            }
        }
    }
</script>
<style lang="less" scoped>
    * {
        font-family: Microsoft YaHei;
    }

    .base-text {
        color: #333333;
        font-size: 16px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
    }

    .selectGather {
        border: 0 !important;
    }

    .total-people {
        height: 12px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
    }

    .warp-padding {
        padding: 27px 23px;
        box-shadow: 0px 5px 15px 0px rgba(27, 27, 78, 0.1);
        border-radius: 4px;
        margin-bottom: 20px;
        background: #ffffff;
        position: relative;
        /*background: red;*/
    }

    .upload-bth {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
    }

    .drawer-head {
        color: #333333;
        font-size: 16px;
        border-bottom: 1px solid #f3f3f3;
        height: 50px;
        line-height: 50px;
        margin-bottom: 20px;
    }

    .drawer-content-bottom {
        margin-bottom: 20px;
    }

    .drawer {
        height: 500px;
    }

    .drawer-bottom {
        width: 100%;
        border-top: 1px solid #f3f3f3;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 80px;
        line-height: 80px;
        padding: 15px 20px;
    }

    .coverImg {
        width: 520px;
        height: 292px;
        background: rgba(252, 252, 252, 1);
        border: 1px solid rgba(243, 243, 243, 1);
        position: relative;
        .cover-upload {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }

    .line-margin {
        margin-bottom: 25px;
    }

    .video {
        width: 162px;
        height: 91px;
        background: #fcfcfc;
        display: inline-block;
        margin-right: 10px;
        border: 1px solid #f3f3f3;
        text-align: center;
        cursor: pointer;
        position: relative;

        .video-title {
            color: rgba(51, 51, 51, 1);
            font-size: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            text-align: left;
        }
        .video-bth {
            display: block;
            color: #9a9a9a;
            font-size: 40px;
            height: 50px;
        }
        .video-upload-time {
            /*width:102px;*/
            font-size: 12px;
            color: rgba(153, 153, 153, 1);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
        }
        video {
            width: 100%;
            height: 100%;
        }
    }

    .coverRight {
        flex: 1;
        margin-left: 28px;
        min-width: 180px;
        .person-text {
            font-size: 14px;
            color: rgba(153, 153, 153, 1);
        }
        .person-avatar {
            margin-left: 12px;
            margin-right: 12px;
        }
    }

    .base-warp {
        height: 330px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        overflow-y: auto;
        padding: 20px 24px 27px 20px;
        box-shadow: 0px 5px 15px 0px rgba(27, 27, 78, 0.1);
        .group {
            margin-bottom: 15px;
            margin-right: 25px;
            .group-label {
                font-size: 14px;
                color: rgba(153, 153, 153, 1);
                width: 70px;
                display: block;
                white-space: nowrap;
            }
            .group-label::after {
                display: inline-block;
                margin-left: 4px;
                color: #f5222d;
                font-size: 14px;
                font-family: SimSun, sans-serif;
                line-height: 1;
                content: '*';
            }
            .label-tips {
                font-size: 14px;
                color: rgba(153, 153, 153, 1);
                width: 70px;
                display: block;
                white-space: nowrap;
            }
            .join-name {
                width: 42px;
                text-align: center;
                color: rgba(153, 153, 153, 1);
                font-size: 14px;
                white-space: nowrap;
            }
        }
    }

    .tutor-text {
        font-size: 14px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
    }

    .tutor {
        max-width: 288px;
        display: inline-block;
        margin-right: 60px;
        margin-bottom: 33px;
        .tutor-info {
            margin-left: 20px;
            flex: 1;
            .tutor-name {
                font-size: 16px;
                color: rgba(51, 51, 51, 1);
                margin-bottom: 10px;
            }
            .tutor-info {
                font-size: 14px;
                color: rgba(102, 102, 102, 1);
            }
        }
    }

    .imsges {
        width: 157px;
        height: 88px;
        border: 1px solid rgba(243, 243, 243, 1);
        margin-bottom: 15px;
        margin-right: 15px;
        display: inline-block;
        img {
            width: 100%;
            height: 100%;
            display: inline-block;
        }
        .img-del {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background: rgba(0, 0, 0, 0.4);
            height: 25px;
            line-height: 25px;
            width: 100%;
            text-align: center;
            color: #ffffff;
        }
    }

    .release {
        margin-right: 25px;
    }

    .search-flex {
        display: inline-block;
        width: 400px;
        height: 36px;
        line-height: 36px;
        margin-bottom: 14px;
        .search-list-item {
            margin-right: 28px;
            display: flex;
            .search-item-label {
                font-size: 14px;
                font-weight: bold;
                color: rgba(102, 102, 102, 1);
                margin-right: 10px;
                width: 60px;
                text-align: right;
            }
            .search-item-input {
                flex: 1;
            }
            .search-item-button {
                margin-right: 10px;
                height: 35px;
                line-height: 35px;
                width: 76px;
                text-align: center;
            }
        }
    }

    .upload-file-item {
        width: 100px;
        padding: 15px 10px;
        margin-right: 10px;
        box-shadow: 0px 2px 8px 0px rgba(117, 113, 249, 0.13);
        border-radius: 2px;
        display: inline-block;
        margin-bottom: 10px;
        i {
            font-size: 28px;
            margin-bottom: 10px;
        }
    }

    .Paper-bg {
        background: url('http://sk-ims-cabinet.sikegroup.com/FkZMrbRt2npUZSw3aPSjpdp7-Ezz') no-repeat !important;
        background-size: 100% 100% !important;
        width: 36px;
        height: 36px;
        display: block;
    }

    .quire-content {
        background: rgba(231, 232, 237, 1);
        border-radius: 5px;
        padding: 5px 20px;
        margin-bottom: 10px;
        display: inline-flex;
        width: 100%;
        .quire-icon {
            display: block;
            background: url('http://sk-ims-cabinet.sikegroup.com/FkKQKL4LdI-i6SJmGVd7-XD4FazH') no-repeat;
            background-size: 100% 100%;
            width: 36px;
            height: 36px;
        }

        .quire-title {
            font-size: 14px;
            color: rgba(51, 51, 51, 1);
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 25px;
            overflow: hidden;
            height: 36px;
            line-height: 36px;
            display: block;
            flex: 1;
        }
        .icon-shibai {
            display: block;
            height: 36px;
            line-height: 36px;
            width: 16px;
        }
    }
</style>
